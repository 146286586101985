import React, { useState } from "react";
import { BiSolidPhoneCall } from "react-icons/bi";
import { BsFillShieldLockFill } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AddPhoneNumber, VerifiedPhone } from "../../redux/auth/authSlice";
import "../../App.css";
import Loader from "../Loader/Loader";
import { GetDecryptEmailformat, GetDecryptReferenceNumberformat } from "../Common/Common";
import { Button } from "antd";

const Otp = () => {
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [otpError, setOtpError] = useState("");

  const { googleSigninData, isLoading } = useSelector((state) => state?.counter);

  const email = GetDecryptEmailformat();
  const referenceNumber = GetDecryptReferenceNumberformat();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // function OnCaptchaVerify() {
  //   if (!window.RecaptchaVerifier) {
  //     window.recaptchaVerifier = new RecaptchaVerifier(
  //       auth,
  //       "recaptcha-container",
  //       {
  //         size: "normal",
  //         callback: (response) => {
  //           OnSignup();
  //         },
  //         "expired-callback": () => {},
  //       }
  //     );
  //   }
  // }

  // function OnSignup(event) {
  //   // event?.preventDefault();
  //   // setLoading(true);
  //   // OnCaptchaVerify();
  //   // const appVerifier = window.recaptchaVerifier;
  //   // const phoneNumber = "+" + ph;
  //   // signInWithPhoneNumber(auth, phoneNumber, appVerifier)
  //   //   .then((confirmationResult) => {
  //   //     window.confirmationResult = confirmationResult;
  //   //     setLoading(false);
  //   //     setShowOtp(true);
  //   //     let obj = {
  //   //       email: email,
  //   //       phone: "+" + ph,
  //   //     };
  //   //     dispatch(AddPhoneNumber({ obj, dispatch }));
  //   //     toast.success("OTP Sent Successfully");
  //   //   })
  //   //   .catch((error) => {
  //   //     setLoading(false);
  //   //     toast.error(error.message);
  //   //   });

  function OnSignup(event) {
    event?.preventDefault();
    setPhoneError("");

    if (!ph) {
      setPhoneError("Phone number is required");
      return;
    }

    setLoading(true);

    const callback = (response) => {
      let obj = {
        phone: "+" + ph,
        email: email,
        reference_number: referenceNumber,
      };
      dispatch(VerifiedPhone({ obj, navigate, dispatch }));
    };

    let obj = {
      email: email,
      phone: "+" + ph,
      reference_number: referenceNumber,
    };
    dispatch(AddPhoneNumber({ obj, dispatch, navigate, callback }));
  }

  function OnOtpverify() {
    setOtpError("");

    if (!otp) {
      setOtpError("OTP is required");
      return;
    }

    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (result) => {
        const user = result?.user;
        if (user) {
          setLoading(false);
          let obj = {
            phone: googleSigninData[0]?.phone ? googleSigninData[0]?.phone : "+" + ph,
            email: googleSigninData[0]?.email ? googleSigninData[0]?.email : email,
            reference_number: googleSigninData[0]?.reference_number ? googleSigninData[0]?.reference_number : referenceNumber,
          };
          dispatch(VerifiedPhone({ obj, navigate, dispatch }));
        } else {
          setOtpError("Error verifying OTP");
        }
      })
      .catch((error) => {
        setLoading(false);
        setOtpError(error.message);
      });
  }

  return (
    <>
      {isLoading && <Loader />}
      <section className="background-radial-gradient overflow-hidden min-100">
        <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
        <div
          className="signuppage mt-5 bg-light text-black text-v p-5 resp_otp_css otp_custom_css"
          style={{
            borderRadius: "15px",
            zIndex: "99999",
            position: "relative",
          }}
        >
          {showOtp ? (
            <div className="optvarificationcontent">
              <span className="d-flex justify-content-center">
                <BsFillShieldLockFill size={40} />
              </span>
              <h6 className="text-center mt-3">Enter Your OTP</h6>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                shouldAutoFocus
                renderInput={(props) => (
                  <input
                    {...props}
                    style={{
                      width: "30px",
                      marginRight: "12px",
                      textAlign: "center",
                    }}
                  />
                )}
              ></OtpInput>
              {otpError && (
                <div
                  style={{
                    color: "red",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  {otpError}
                </div>
              )}
              <div className="d-flex justify-content-center">
                <button className="btn btn-primary mt-3 w-75" onClick={OnOtpverify} disabled={loading}>
                  {loading && <span className="spinner-border spinner-border-sm" style={{ marginRight: "10px" }}></span>}
                  <span>Verify OTP</span>
                </button>
              </div>
            </div>
          ) : (
            <div>
              <span className="d-flex justify-content-center">
                <BiSolidPhoneCall size={40} />
              </span>
              <h6 className="text-center mt-3">Please Add your phone number</h6>
              <div className="resp_otp_css_form">
                <PhoneInput
                  country={"in"}
                  value={ph}
                  onChange={setPh}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      OnSignup();
                    }
                  }}
                />
              </div>
              {phoneError && (
                <div
                  style={{
                    color: "red",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  {phoneError}
                </div>
              )}
              <div className="d-flex justify-content-center">
                <button className="btn btn-primary mt-3 w-75" onClick={OnSignup} disabled={loading}>
                  {loading && <span className="spinner-border spinner-border-sm" style={{ marginRight: "10px" }}></span>}
                  <span>Submit</span>
                </button>
              </div>
              <br />
              <div id="recaptcha-container" className="mt-6"></div>
            </div>
          )}
        </div>
        <Button
          type="text"
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate("/")}
          style={{
            fontSize: "15px",
            position: "absolute",
            top: "16px",
            left: "16px",
            zIndex: "99999",
          }}
        >
          Go Back
        </Button>
        <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>
        <div className="row position-absolute text-white p-5"></div>
      </section>
    </>
  );
};

export default Otp;
