import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { GetGmailAuth, GetMailRidirect } from "../../redux/auth/authSlice";
import Loader from "../../Components/Loader/Loader";
import { GetDecryptEmailformat, GetDecryptReferenceNumberformat } from "../../Components/Common/Common";

const Gmail = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state?.counter);
  const email = GetDecryptEmailformat();

  const referenceNumber = GetDecryptReferenceNumberformat();

  const HandleDashboard = (item) => {
    navigate(item);
  };

  const HandleGmailAuth = () => {
    let obj = {
      email: email,
      reference_number: referenceNumber,
    };

    dispatch(GetGmailAuth({ obj, dispatch }));
  };

  return (
    <>
      {isLoading && <Loader />}
      <div>
        <section className="background-radial-gradient overflow-hidden min-100">
          <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>

          <div className="form_css resp_height" style={{ height: "445px", zIndex: "9" }}>
            <div className="d-flex justify-content-center">
              <div className="my-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="130" height="130" viewBox="0 0 32 32" id="gmail">
                  <path fill="#ea4435" d="M16.58,19.1068l-12.69-8.0757A3,3,0,0,1,7.1109,5.97l9.31,5.9243L24.78,6.0428A3,3,0,0,1,28.22,10.9579Z" />
                  <path fill="#00ac47" d="M25.5,5.5h4a0,0,0,0,1,0,0v18a3,3,0,0,1-3,3h0a3,3,0,0,1-3-3V7.5a2,2,0,0,1,2-2Z" transform="rotate(180 26.5 16)" />
                  <path
                    fill="#ffba00"
                    d="M29.4562,8.0656c-.0088-.06-.0081-.1213-.0206-.1812-.0192-.0918-.0549-.1766-.0823-.2652a2.9312,2.9312,0,0,0-.0958-.2993c-.02-.0475-.0508-.0892-.0735-.1354A2.9838,2.9838,0,0,0,28.9686,6.8c-.04-.0581-.09-.1076-.1342-.1626a3.0282,3.0282,0,0,0-.2455-.2849c-.0665-.0647-.1423-.1188-.2146-.1771a3.02,3.02,0,0,0-.24-.1857c-.0793-.0518-.1661-.0917-.25-.1359-.0884-.0461-.175-.0963-.267-.1331-.0889-.0358-.1837-.0586-.2766-.0859s-.1853-.06-.2807-.0777a3.0543,3.0543,0,0,0-.357-.036c-.0759-.0053-.1511-.0186-.2273-.018a2.9778,2.9778,0,0,0-.4219.0425c-.0563.0084-.113.0077-.1689.0193a33.211,33.211,0,0,0-.5645.178c-.0515.022-.0966.0547-.1465.0795A2.901,2.901,0,0,0,23.5,8.5v5.762l4.72-3.3043a2.8878,2.8878,0,0,0,1.2359-2.8923Z"
                  />
                  <path fill="#4285f4" d="M5.5,5.5h0a3,3,0,0,1,3,3v18a0,0,0,0,1,0,0h-4a2,2,0,0,1-2-2V8.5a3,3,0,0,1,3-3Z" />
                  <path
                    fill="#c52528"
                    d="M2.5439,8.0656c.0088-.06.0081-.1213.0206-.1812.0192-.0918.0549-.1766.0823-.2652A2.9312,2.9312,0,0,1,2.7426,7.32c.02-.0475.0508-.0892.0736-.1354A2.9719,2.9719,0,0,1,3.0316,6.8c.04-.0581.09-.1076.1342-.1626a3.0272,3.0272,0,0,1,.2454-.2849c.0665-.0647.1423-.1188.2147-.1771a3.0005,3.0005,0,0,1,.24-.1857c.0793-.0518.1661-.0917.25-.1359A2.9747,2.9747,0,0,1,4.3829,5.72c.089-.0358.1838-.0586.2766-.0859s.1853-.06.2807-.0777a3.0565,3.0565,0,0,1,.357-.036c.076-.0053.1511-.0186.2273-.018a2.9763,2.9763,0,0,1,.4219.0425c.0563.0084.113.0077.169.0193a2.9056,2.9056,0,0,1,.286.0888,2.9157,2.9157,0,0,1,.2785.0892c.0514.022.0965.0547.1465.0795a2.9745,2.9745,0,0,1,.3742.21A2.9943,2.9943,0,0,1,8.5,8.5v5.762L3.78,10.9579A2.8891,2.8891,0,0,1,2.5439,8.0656Z"
                  />
                </svg>
              </div>
            </div>

            <div className="w-100 py-3">
              <div className="d-flex justify-content-center gap-3  resp_add_social_info" style={{ marginTop: "50px" }}>
                <button
                  // type="submit"
                  className="btn form_button_css"
                  style={{
                    background: "#17c1e8",
                    color: "white",
                    width: "60%",
                    fontSize: "13px",
                    zIndex: "9",
                    padding: "13px",
                  }}
                  onClick={HandleGmailAuth}
                >
                  <span>Authentication</span>
                </button>
              </div>
            </div>

            {/* <div style={{marginTop:"-30px",textAlign:"center"}}>
                    
                    <Link to="/mail"><p>search mail words</p></Link>
                  </div> */}

            <div>
              <button onClick={() => HandleDashboard("/dashboard")} className="w-100 p-2 gmail_button_css">
                Back to Dashboard
              </button>
            </div>
          </div>

          <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>

          <div className="row position-absolute  text-white p-5"></div>
        </section>
      </div>
    </>
  );
};

export default Gmail;
