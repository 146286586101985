import React from "react";
import Loader from "../../Loader/Loader";
import { useNavigate } from "react-router";
import { FaInstagram } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { InstagramHandler, InstagramRevoke } from "../../../redux/auth/authSlice";
import { GetDecryptEmailformat, GetDecryptReferenceNumberformat } from "../../Common/Common";
import { Badge } from "antd";
const AllowInstaAccess = ({ hasInstaAccess }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state?.counter);
  const email = GetDecryptEmailformat();
  const referenceNumber = GetDecryptReferenceNumberformat();

  // const handleDashboard = (item) => {
  //   navigate(item);
  // };

  const HandleInstagramAuth = () => {
    let obj = {
      email: email,
      reference_number: referenceNumber,
      operation_type: "authorize",
    };

    dispatch(InstagramHandler({ obj, dispatch, navigate }));
  };

  const HandleInstagramRevoke = () => {
    let obj = {
      email: email,
      reference_number: referenceNumber,
      operation_type: "deauthorize",
    };

    dispatch(InstagramRevoke({ obj, dispatch, navigate }));
  };

  return (
    <>
      {isLoading && <Loader />}
      <section className="background-radial-gradient-instagram overflow-hidden">
        <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
        <div
          style={{
            borderRadius: "15px",
            width: "100%",
            maxWidth: "580px",
          }}
        >
          <Badge.Ribbon color={hasInstaAccess ? "#17c1e8" : "red"} text={hasInstaAccess ? "Access granted" : "Not accessed"} style={{ width: "32%" }}>
            <div className="form_css resp_height" style={{ height: "380px", marginTop: "80px" }}>
              <div>
                <h1 style={{ fontSize: "16px" }}> Instagram Permission</h1>
              </div>
              <div className="d-flex justify-content-center">
                <FaInstagram size={95} className="instagram_logo_css" />
              </div>

              <div className="w-100 py-3">
                <div className="d-flex justify-content-center gap-3  resp_add_social_info" style={{ marginTop: "50px" }}>
                  {hasInstaAccess ? (
                    <button
                      // type="submit"
                      className="btn form_button_css"
                      style={{
                        background: "#f3797e",
                        color: "white",
                        width: "60%",
                        fontSize: "13px",
                      }}
                      onClick={HandleInstagramRevoke}
                    >
                      Revoke Instagram Access
                    </button>
                  ) : (
                    <button
                      className="btn form_button_css"
                      style={{
                        background: "#17c1e8",
                        color: "white",
                        width: "60%",
                        fontSize: "13px",
                      }}
                      onClick={HandleInstagramAuth}
                    >
                      Allow Instagram Access
                    </button>
                  )}

                  {/* <button
                onClick={() => handleDashboard("/dashboard")}
                className="w-100 p-2 insta_button_css"
                
              >
                Back to Dashboard
              </button> */}
                </div>
              </div>
            </div>
          </Badge.Ribbon>
        </div>
        <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>
        <div className="row position-absolute  text-white p-5"></div>
      </section>
    </>
  );
};

export default AllowInstaAccess;
