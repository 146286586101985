import React, { useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { BsFillShieldLockFill } from "react-icons/bs";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { RequestEmailOtp, ConfirmEmail } from "../../redux/auth/authSlice";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";

import OtpInput from "react-otp-input";
import { GetDecryptEmailformat, GetDecryptReferenceNumberformat } from "../Common/Common";
import { Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

const VerifyOTP = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { googleSigninData, isLoading } = useSelector((state) => state?.counter);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);

  const email = GetDecryptEmailformat();
  const referenceNumber = GetDecryptReferenceNumberformat();

  const sendOPT = () => {
    let obj = {
      email: email,
      reference_number: referenceNumber,
    };
    dispatch(RequestEmailOtp({ obj, dispatch }));
  };

  useEffect(() => {
    if (!localStorage.getItem("isOtpSent")) {
      sendOPT();
    }
  }, [googleSigninData, dispatch]);

  const handleResendOTP = () => {
    sendOPT();
  };

  const handleVerifyOtp = () => {
    if (otp.trim() === "") {
      setOtpError(true);
      return;
    }

    let obj = {
      email: email,
      reference_number: referenceNumber,
      otp: otp,
    };
    dispatch(ConfirmEmail({ obj, navigate, dispatch }));
  };

  return (
    <>
      {isLoading && <Loader />}
      <section className="background-radial-gradient overflow-hidden min-100">
        <div className="d-flex justify-content-center">
          <Toaster toastOptions={{ duration: 4000 }} />
          <Button
            type="text"
            icon={<ArrowLeftOutlined />}
            onClick={() => navigate("/")}
            style={{
              fontSize: "15px",
              position: "absolute",
              top: "16px",
              left: "16px",
              zIndex: "99999",
            }}
          >
            Go Back
          </Button>
          <div className="row position-absolute">
            <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>

            <div
              className="signuppage mt-5 bg-light text-black p-5 verify_otp_css"
              style={{
                zIndex: "9999",
                borderRadius: "15px",
              }}
            >
              <div className="optvarificationcontent">
                <span className="d-flex justify-content-center">
                  <BsFillShieldLockFill size={40} />
                </span>
                <h6 className="text-center mt-3">Enter Your Email OTP</h6>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  shouldAutoFocus
                  renderInput={(props) => (
                    <input
                      {...props}
                      style={{
                        width: "30px",
                        marginRight: "12px",
                        textAlign: "center",
                      }}
                    />
                  )}
                />
                {otpError && (
                  <div
                    style={{
                      color: "red",
                      textAlign: "center",
                      marginTop: "10px",
                    }}
                  >
                    Please enter the OTP.
                  </div>
                )}
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <button className="btn btn-primary mt-3 w-75" onClick={handleVerifyOtp} disabled={otp.trim() === ""}>
                    <span>Verify OTP</span>
                  </button>
                </div>
              </div>
            </div>

            <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VerifyOTP;
