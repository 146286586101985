import React from "react";
import { Form, Input, Button, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

import "../../style/admin.css";
import { HandleUpdateUserRole } from "../../redux/auth/authSlice";
import { useDispatch } from "react-redux";

const { Title, Text } = Typography;

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinish = (values) => {
    localStorage.setItem("access_token", "hkaskhdk3222ahshaks2234234dhasd.8ewrw86ewrwerwrewe3e32e2r43t343ttt");
    dispatch(HandleUpdateUserRole("ADMIN"));
    navigate("/admindashboard");
  };

  return (
    <>
      <div
        className="signuppage mt-5 bg-light text-black text-v p-5 resp_otp_css otp_custom_css admin_login_css"
        style={{ maxWidth: "400px", margin: "auto", padding: "20px", textAlign: "center" }}
      >
        <Title level={4}>Admin Login</Title>

        <Form name="trouble_logging_in" layout="vertical" onFinish={onFinish} style={{ marginTop: "20px" }}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter your email",
              },
              {
                type: "email",
                message: "Invalid email address",
              },
            ]}
          >
            <Input placeholder="Enter your email" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter your password",
              },
              {
                min: 6,
                message: "Password must be at least 6 characters long",
              },
            ]}
          >
            <Input.Password placeholder="Enter your password" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block size="large">
              Submit
            </Button>
          </Form.Item>
        </Form>

        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Text>OR</Text>
        </div>

        <Link
          to="/"
          style={{
            display: "block",
            marginBottom: "20px",
            textDecoration: "none",
            fontSize: "15px",
            color: "black",
          }}
        >
          Back to Login
        </Link>
      </div>

      <Button
        type="text"
        icon={<ArrowLeftOutlined />}
        onClick={() => navigate("/")}
        style={{
          fontSize: "15px",
          position: "absolute",
          top: "16px",
          left: "16px",
          zIndex: "99999",
        }}
      >
        Go Back
      </Button>
    </>
  );
};

export default ForgotPassword;
