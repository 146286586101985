import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddMinting,
  AddMultiplePost,
  AddPost,
  GeneratedCaptionApi,
  GetInstagramDetails,
  GetInstagramHashTag,
  GetInstagramUserDetailsInfo,
  GetTokenId,
  HandleTokenIdGenerate,
  InstagramMessageApi,
  PostTokenId,
  SetLoading,
  UploadPostInstagram,
} from "../../redux/auth/authSlice";
import Loader from "../../Components/Loader/Loader";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
  GetDecryptEmailformat,
  GetDecryptInstagramEID,
  GetDecryptInstagramUID,
  GetDecryptPasswordformat,
  GetDecryptReferenceNumberformat,
  GetDecryptUserNameformat,
  SetEncryptTokenID,
  SetEncrypytInstagramPID,
  SetEncrypytInstagramUID,
} from "../../Components/Common/Common";
import InsightModal from "../../Components/ViewInsights/InsightModal";
import { Modal } from "antd";
// import { useWalletClient, useAccount } from "wagmi";
// import { ethers } from "ethers";
// import { ContractAbi, ContractAddress } from "../../Components/Common/MetaMaskContract";

import Web3 from "web3";
import { useNavigate } from "react-router-dom";
import InstagramPage from "../../Components/UserPanel/Instagram/InstagramPage";
import AllowInstaAccess from "../../Components/UserPanel/Instagram/AllowInstaAccess";
import SetupCredentialsForm from "../../Components/UserPanel/Instagram/SetupCredentialsForm";

const Instagram = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [instaonchangedataModal, setInstaonchangedataModal] = useState({
    username: "",
    password: "",
  });

  const [error, setError] = useState({});

  const [errorForModal, setErrorForModal] = useState({});

  const [fileupload, setFileupload] = useState("");

  const [togglePassword, setTogglePassword] = useState(false);

  // const [hashtag, setHashtag] = useState("");
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // const { data: walletClient } = useWalletClient();
  // const { address } = useAccount();

  const decryptusername = GetDecryptUserNameformat();

  const decryptpassword = GetDecryptPasswordformat();

  const email = GetDecryptEmailformat();

  const referenceNumber = GetDecryptReferenceNumberformat();

  const dispatch = useDispatch();
  const [instaonchangedata, setInstaonchangedata] = useState({
    username: decryptusername ? decryptusername : "",
    password: decryptpassword ? decryptpassword : "",
    caption: "",
  });
  const [rememberMe, setRememberMe] = useState(false);

  // const [hashTokenID, setHashTokenID] = useState("");

  const {
    isLoading,
    HashedTokenID,
    walletBalanceUser,
    getUserWalletDetails,
    S3BucketImage,
    TokenID,
    InstagramUserDetails,
    isInsiteDataLoading,
    InstagramProfileData,
    ImageCaptionGenerate,
    hasInstaAccess,
  } = useSelector((state) => state?.counter);

  // const did = SetEncryptTokenID(TokenID);

  // useEffect(() => {
  //   if (did && !hashTokenID) {
  //     setHashTokenID(did);
  //   }
  // }, [did]);

  // let followersString = Number(InstagramProfileData?.followersCount).toString();

  // const formData = {
  //   facebook: "0",
  //   instagram: InstagramUserDetails?.username ? InstagramUserDetails?.username : "0",
  //   xplatform: "0",
  //   linkedin: "0",
  //   fb_followers: "0",
  //   insta_followers: followersString ? followersString : "0",
  //   x_followers: "0",
  //   lnkd_followers: "0",
  // };

  // const formDataupdate = {
  //   tokenId: TokenID ? TokenID : "0",
  //   facebook: "0",
  //   instagram: InstagramUserDetails?.username ? InstagramUserDetails?.username : "0",
  //   xplatform: "0",
  //   linkedin: "0",
  //   fb_followers: "0",
  //   insta_followers: followersString ? followersString : "0",
  //   x_followers: "0",
  //   lnkd_followers: "0",
  // };

  const imageInputRef = useRef(null);

  useEffect(() => {
    setInstaonchangedata({ ...instaonchangedata, caption: ImageCaptionGenerate });
  }, [ImageCaptionGenerate]);

  const navigate = useNavigate();

  const HandleChange = (e) => {
    setInstaonchangedata({ ...instaonchangedata, [e.target.id]: e.target.value });
    setError({ ...error, [e.target.id]: undefined });
  };

  const HandleChangeForModal = (e) => {
    setInstaonchangedataModal({ ...instaonchangedataModal, [e.target.id]: e.target.value });
    setErrorForModal({ ...errorForModal, [e.target.id]: undefined });
  };

  const HandleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Check file size (2MB = 2 * 1024 * 1024 bytes)
      if (file.size > 2 * 1024 * 1024) {
        // setError('File size must be less than 2MB.')
        setError({ ...error, imgsize: "File format must be JPG." });
        toast.error("File size must be less than 2MB.");
        setFileupload(null);
        return;
      }

      // Check file type
      if (!file.type.includes("image/jpeg")) {
        setError({ ...error, imgerr: "File format must be JPG." });
        toast.error("File format must be JPG.");
        setFileupload(null);
        return;
      }

      // Clear error and set file
      if (file) {
        setFileupload(file);
        let obj = {
          file: file,
          file_name: file?.name,
          file_type: file?.type,
        };
        dispatch(GeneratedCaptionApi({ email, referenceNumber, image: file, dispatch, obj }));
      }

      setError({ ...fileupload, [e.target.id]: undefined });
    }

    // setFileupload(e.target.files[0]);
    // setError({ ...fileupload, [e.target.id]: undefined });
  };

  // const ValidationforHashtag = () => {
  //   let errors = {};

  //   if (hashtag === "" && hashtag?.length === 0) {
  //     errors.hashtag = "*Required";
  //   }
  //   setError(errors);

  //   const hasErrors = Object.keys(errors).length > 0;
  //   if (hasErrors) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const Validation = () => {
    let errors = {};

    if (instaonchangedata?.username === "" && instaonchangedata?.username?.length === 0) {
      errors.username = "*Required";
    }
    if (instaonchangedata?.password === "" && instaonchangedata?.username?.length === 0) {
      errors.password = "*Required";
    }
    if (instaonchangedata?.caption === "" && instaonchangedata?.caption?.length === 0) {
      errors.caption = "*Required";
    }

    if (fileupload === "" && fileupload?.length === 0) {
      errors.image = "*Required";
    }

    setError(errors);

    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      return true;
    } else {
      return false;
    }
  };

  const ValidationForMdal = () => {
    let errors = {};

    if (instaonchangedataModal?.username === "" && instaonchangedata?.username?.length === 0) {
      errors.username = "*Required";
    }
    if (instaonchangedataModal?.password === "" && instaonchangedata?.password?.length === 0) {
      errors.password = "*Required";
    }

    setErrorForModal(errors);

    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      return true;
    } else {
      return false;
    }
  };

  let obj = {
    email: email,
    reference_number: referenceNumber,
    username: instaonchangedata?.username ? instaonchangedata?.username : decryptusername,
    password: instaonchangedata?.password ? instaonchangedata?.password : decryptpassword,
  };

  // let objhashtag = {
  //   email: email,
  //   hashtag: hashtag,
  //   reference_number: referenceNumber,
  //   username: instaonchangedata?.username ? instaonchangedata?.username : decryptusername,
  //   password: instaonchangedata?.password ? instaonchangedata?.password : decryptpassword,
  // };

  const HandleSubmit = (e) => {
    e?.preventDefault();

    let validData = Validation();

    if (validData) {
      return;
    } else {
      setFileupload("");
      setInstaonchangedata({
        username: "",
        password: "",
        caption: "",
      });

      dispatch(UploadPostInstagram({ instaonchangedata, fileupload, dispatch, email, referenceNumber, obj }));
      let removeimgvalue = (imageInputRef.current.value = "");
      return removeimgvalue;
    }
  };

  // useEffect(() => {

  //   const connectToMetaMask = async () => {
  //     try {
  //       // Check if MetaMask is installed
  //       if (window.ethereum) {
  //         // Request account access if needed
  //         await window.ethereum.request({ method: "eth_requestAccounts" });
  //         // MetaMask is connected

  //         // Get accounts
  //         const accounts = await window.ethereum.request({ method: "eth_accounts" });
  //         if (accounts) {
  //           toast.success("Wallet Connected");
  //         }
  //       } else {
  //         toast.error("Wallet Not Connected");
  //
  //       }
  //     } catch (error) {
  //       toast.error("Wallet Not Connected");
  //
  //     }
  //   };

  //   connectToMetaMask();
  // }, []);

  // const handleMint = async (event) => {
  //   event?.preventDefault();
  //   if (!InstagramProfileData) {
  //     showModal();
  //     return;
  //   }

  //   if (TokenID) {
  //     let obj = {
  //       email: email,
  //       reference_number: referenceNumber,
  //       token_id: TokenID,
  //     };
  //     dispatch(  ({ obj, dispatch }));
  //     return;
  //   }

  //   if (!walletClient) {
  //
  //     return;
  //   }

  //   try {
  //     dispatch(SetLoading(true));
  //     const provider = new ethers.BrowserProvider(walletClient);
  //     const signer = await provider.getSigner();
  //     const contract = new ethers.Contract(ContractAddress, ContractAbi, signer);

  //     const transaction = await contract.mint(
  //       ethers.parseUnits("1", 18), // Mint 1 token as an example
  //       formData.facebook,
  //       formData.instagram,
  //       formData.xplatform,
  //       formData.linkedin,
  //       formData.fb_followers,
  //       formData.insta_followers,
  //       formData.x_followers,
  //       formData.lnkd_followers
  //     );

  //     const receipt = await transaction.wait();

  //     // Parse the logs to find the TokenMinted event
  //     const iface = new ethers.Interface(ContractAbi);
  //     const log = receipt.logs.find((log) => {
  //       try {
  //         const parsedLog = iface.parseLog(log);
  //         // console.log("Parsed log:", parsedLog);
  //         return parsedLog.name === "TransferSingle";
  //       } catch (e) {
  //         return false;
  //       }
  //     });

  //     if (log) {
  //       const parsedLog = iface.parseLog(log);
  //       // console.log("Parsed log1:", parsedLog); // Debug log
  //       const newTokenId = parseInt(parsedLog.args[3]);

  //       dispatch(SetLoading(false));

  //       if (newTokenId) {
  //         let obj = {
  //           email: email,
  //           reference_number: referenceNumber,
  //           token_id: newTokenId,
  //         };
  //         const callBack = (id) => {
  //           dispatch(HandleTokenIdGenerate(id));
  //         };
  //         dispatch(PostTokenId({ obj, dispatch }, callBack));
  //         // SetEncryptTokenID(newTokenId)
  //       } else {
  //         dispatch(SetLoading(false));
  //         console.log(error, "err");
  //         toast?.error(" Token ID not found ");
  //       }

  //       // setTokenId(newTokenId.toString());
  //     } else {
  //       dispatch(SetLoading(false));
  //       console.error("TokenMinted event not found in the logs.");
  //     }
  //     // console.log("Transaction:", receipt);
  //   } catch (error) {
  //     toast?.error(error);
  //     dispatch(SetLoading(false));
  //     console.error("Error performing transaction:", error.message);
  //   }
  // };

  // const HandleViewInsite = (e) => {
  //   e?.preventDefault();

  //   let validData = ValidationForMdal();

  //   if (validData) {
  //     return;
  //   } else {
  //     setInstaonchangedataModal({
  //       username: "",
  //       password: "",
  //     });
  //     const obj = {
  //       email: email,
  //       reference_number: referenceNumber,
  //       username: instaonchangedataModal?.username,
  //       password: instaonchangedataModal?.password,
  //     };

  //     const callBack = () => {
  //       handleMint();
  //     };

  //     dispatch(GetInstagramUserDetailsInfo({ obj, dispatch, callBack }));
  //     handleCancel();
  //   }
  // };

  function getCookie(name) {
    const cookieName = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookiesArray = decodedCookie.split(";");

    for (let i = 0; i < cookiesArray.length; i++) {
      let cookie = cookiesArray[i].trim();
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return null;
  }

  const HandleViewInsite = (e) => {
    e?.preventDefault();
    const email1 = getCookie("InsEID");
    const password1 = getCookie("InsPID");

    if (rememberMe && !email1 && !password1) {
      const days = 15;
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      const expires = date.toUTCString();

      const encryptusername = SetEncrypytInstagramUID(instaonchangedataModal?.username);

      const encryptpassword = SetEncrypytInstagramPID(instaonchangedataModal?.password);

      document.cookie = `InsEID=${encryptusername}; expires=${expires}; path=/`;
      document.cookie = `InsPID=${encryptpassword}; expires=${expires}; path=/`;
      document.cookie = `InsRM=${rememberMe}; expires=${expires}; path=/`;
    } else if (!rememberMe && email1 && password1) {
      document.cookie = `InsEID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      document.cookie = `InsPID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      document.cookie = `InsRM=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
    }

    let validData = ValidationForMdal();

    if (validData) {
      return;
    } else {
      setInstaonchangedataModal({
        username: "",
        password: "",
      });
      const obj = {
        email: email,
        reference_number: referenceNumber,
        username: instaonchangedataModal?.username,
        password: instaonchangedataModal?.password,
      };
      dispatch(GetInstagramUserDetailsInfo({ obj, dispatch, TokenID, getUserWalletDetails, walletBalanceUser }));
      handleCancel();
    }
  };

  useEffect(() => {
    const rememberMe = getCookie("InsRM");
    if (rememberMe) {
      const email1 = getCookie("InsEID");
      const password2 = getCookie("InsPID");

      let username = GetDecryptInstagramEID(email1);

      let password = GetDecryptInstagramUID(password2);

      setInstaonchangedataModal((prevState) => ({ ...prevState, username, password }));
      setRememberMe(rememberMe);
    }
  }, []);

  const TogglePasswordVisibility = () => {
    setTogglePassword(!togglePassword);
  };

  // const HandleHashTag = () => {
  //   let validData = ValidationforHashtag();

  //   if (validData) {
  //     return;
  //   } else {
  //     setHashtag("");
  //     dispatch(GetInstagramHashTag({ objhashtag, dispatch }));
  //   }
  // };

  const HandleLoginInsta = (e) => {
    e?.preventDefault();
    showModal();
  };

  useEffect(() => {
    if (getUserWalletDetails) {
      dispatch(GetInstagramUserDetailsInfo({ obj, dispatch, TokenID, getUserWalletDetails, walletBalanceUser }));
    }
  }, [TokenID]);

  useEffect(() => {
    if (getUserWalletDetails) {
      dispatch(GetInstagramDetails({ email, referenceNumber, dispatch }));
    }
  }, [getUserWalletDetails]);

  // useEffect(() => {
  //   dispatch(GetInstagramDetails({ email, referenceNumber, dispatch }));
  //   dispatch(GetInstagramUserDetailsInfo({ obj, dispatch }));
  //   dispatch(GetTokenId({ obj, dispatch }));
  // }, []);

  const base64Image = (InstagramProfileData?.profilePicUrl && `data:image/png;base64,${InstagramProfileData?.profilePicUrl}`) || null;

  // useEffect(() => {
  //   if (!isInsiteDataLoading && !InstagramProfileData) {
  //     showModal();
  //   }
  // }, [InstagramProfileData, isInsiteDataLoading, TokenID]);

  // const updateMetadatafx = async () => {
  //   if (!InstagramProfileData) {
  //     showModal();
  //     return;
  //   }

  //   if (typeof window.ethereum !== "undefined") {
  //     try {
  //       dispatch(SetLoading(true));
  //       await window.ethereum.request({ method: "eth_requestAccounts" });
  //       const web3 = new Web3(window.ethereum);
  //       const accounts = await web3.eth.getAccounts();
  //       const contract = new web3.eth.Contract(ContractAbi, ContractAddress);

  //       // Ensure all required argument      s are passed
  //       const tx = await contract.methods
  //         .updateMetadata(
  //           formDataupdate.tokenId,
  //           formDataupdate.facebook,
  //           formDataupdate.instagram,
  //           formDataupdate.xplatform,
  //           formDataupdate.linkedin,
  //           formDataupdate.fb_followers,
  //           formDataupdate.insta_followers,
  //           formDataupdate.x_followers,
  //           formDataupdate.lnkd_followers
  //         )
  //         .send({ from: accounts[0] });
  //       dispatch(SetLoading(false));

  //       // console.log("Transaction hash:", tx.transactionHash);

  //       console.log("Metadata updated!");
  //     } catch (error) {
  //       toast?.error(error);
  //       dispatch(SetLoading(false));
  //       console.error("Error updating metadata:", error);
  //       setError("Error updating metadata: " + error.message);
  //     }
  //   } else {
  //     dispatch(SetLoading(false));
  //     console.error("Ethereum object not found, install MetaMask.");
  //     setError("Ethereum object not found, install MetaMask.");
  //   }
  // };

  const HandleTransaction = () => {
    navigate("/transactionhistory");
  };

  const HandleChecked = (event) => {
    setRememberMe(event?.target?.checked);
  };

  // const Handlemulti = () => {
  //   const postMultipledetails = {
  //     userTokenID: 130,
  //     postUrl: [
  //       "https://www.twitter.com/help/20340361208222571212",
  //       "https://www.twitter.com/help/20340361208222571212",
  //       "https://www.twitter.com/help/20340361208222571212",
  //       "https://www.twitter.com/help/20340361208222571212",
  //       "https://www.twitter.com/help/20340361208222571212",
  //       "https://www.twitter.com/help/20340361208222571212",
  //       "https://www.twitter.com/help/20340361208222571212",
  //       "https://www.twitter.com/help/20340361208222571212",
  //       "https://www.twitter.com/help/20340361208222571212",
  //       "https://www.twitter.com/help/20340361208222571212",
  //       "https://www.twitter.com/help/20340361208222571212",
  //       "https://www.twitter.com/help/20340361208222571212",
  //       "https://www.twitter.com/help/20340361208222571212",
  //       "https://www.twitter.com/help/20340361208222571212",
  //       "https://www.twitter.com/help/20340361208222571212",
  //       "https://www.twitter.com/help/20340361208222571212",
  //       "https://www.twitter.com/help/20340361208222571212",
  //       "https://www.twitter.com/help/20340361208222571212",
  //       "https://www.twitter.com/help/20340361208222571212",
  //       "https://www.twitter.com/help/20340361208222571212",
  //       "https://www.twitter.com/help/20340361208222571212",
  //     ],
  //     platformType: ["insta", "fb", "insta", "fb", "insta", "fb", "insta", "fb", "insta", "fb", "insta", "fb", "insta", "fb", "insta", "fb", "insta", "fb", "insta", "fb", "insta"],
  //     multiple: true,
  //     id: "378458586685865875_8756756578",
  //     is_already_mint_for_multiple: 10,
  //     walletAddress: "scjghshjbdssd",
  //   };

  //   dispatch(AddMultiplePost({ postMultipledetails }));
  // };

  // const postdetails = [
  //   {
  //     userTokenID: 130,
  //     postUrl: "https://www.twitter.com/help/20340361208222571212",
  //     platformType: "twitter",
  //     multiple: false,
  //     id:"378458586685865875_8756756578",
  //     walletAddress: "fhhvvjhj",
  //   },
  //   {
  //     userTokenID: 130,
  //     postUrl: "https://www.twitter.com/help/20340361208222571212",
  //     platformType: "twitter",
  //     multiple: false,
  //     id:"378458586685865875_8756756578",
  //     walletAddress: "fhhvvjhj",
  //   },
  // ];

  // const formData = {
  //   fbUsername: "0",
  //   instaUsername: "areaxxxx",
  //   linkedinUsername: "0",
  //   xUsername: "0",
  //   fbFollowers: "0",
  //   instaFollowers:  "0",
  //   linkedinFollowers: "0",
  //   xFollowers: "0",
  //   walletAddress: "fhhvvjhj",
  // };

  // const handleAddd=()=>{
  //   dispatch(AddPost({ postdetails }));
  // }

  // const handleAdddMint=()=>{
  //   dispatch(AddMinting({ formData }));
  // }

  // const HandleMint = () => {
  //   if (walletBalanceUser && walletBalanceUser !== "You have not enough balance 0.,please add more balance.") {
  //     dispatch(GetInstagramUserDetailsInfo({ obj, dispatch, TokenID, getUserWalletDetails, walletBalanceUser }));
  //   } else {
  //     toast.error("Please add amount in your wallet!");
  //   }
  //   const formData = {
  //     fbUsername: "0",
  //     instaUsername: "username",
  //     linkedinUsername: "0",
  //     xUsername: "0",
  //     fbFollowers: "0",
  //     instaFollowers: "0",
  //     linkedinFollowers: "0",
  //     xFollowers: "0",
  //     walletAddress: getUserWalletDetails,
  //   };

  //   dispatch(AddMinting({ formData }));
  // };

  // if (!hasInstaAccess) {

  //   return <AllowInstaAccess />;
  // }

  // if (!isInsiteDataLoading && !InstagramProfileData) {
  //   return <SetupCredentialsForm />;
  // }

  return <InstagramPage hasInstaAccess={hasInstaAccess} />;
  return;
  return (
    <>
      <div>
        <div>
          <div className="row d-flex justify-content-center">
            {/* <button onClick={HandleMint}>add mint token</button> */}
            {/* <div clas              sName="p-3 d-flex">
              <div className="w-100">
                <input
                  className="p-2"
                  type="text"
                  value={hashtag}
                  placeholder="Enter Something..."
                  style={{ width: "70%", borderRadius: "10px", border: "1px solid grey" }}
                  onChange={(e) => {
                    setHashtag(e.target.value);
                    setError("");
                  }}
                />
                <p style={{ color: "red" }}>{error?.hashtag}</p>
              </div>
              <div>
                <button className="btn btn-primary" onClick={HandleHashTag}>
                  Search
                </button>
              </div>
            </div> */}
            <div className="col-lg-6">
              <div className="card cus-m" style={{ borderRadius: "15px" }}>
                <div className="card-body p-4">
                  {TokenID && (
                    <>
                      {HashedTokenID ? (
                        <>
                          <p style={{ color: "#4b49ac", fontSize: "12px", fontWeight: "600" }}>DID:- {HashedTokenID ? HashedTokenID : ""}</p>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}

                  <div className="d-flex custom-flex-100">
                    <div className="flex-shrink-0">
                      <img
                        src={base64Image ? base64Image : "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-profiles/avatar-1.webp"}
                        alt="Generic placeholder image"
                        className="img-fluid"
                        style={{ width: "180px", borderRadius: "10px" }}
                      />
                    </div>

                    <div className="flex-grow-1 ms-3">
                      <h5 className="mb-1">{InstagramUserDetails?.username ? InstagramUserDetails?.username : ""}</h5>
                      <p className="mb-2 pb-1"></p>
                      <div className="d-flex justify-content-start rounded-3 p-2 mb-2 bg-body-tertiary">
                        {/* <div className="wid-100">
                          <p className="small text-muted mb-1">Articles</p>
                          <p className="mb-0">41</p>
                        </div> */}
                        <div className="px-3 wid-100">
                          <p className="small text-muted mb-1">Account Type</p>
                          <p className="mb-0">{InstagramUserDetails?.account_type ? InstagramUserDetails?.account_type : ""}</p>
                        </div>
                        <div className="wid-100">
                          <p className="small text-muted mb-1">Media Count</p>
                          <p className="mb-0">{InstagramUserDetails?.media_count ? InstagramUserDetails?.media_count : ""}</p>
                        </div>
                      </div>

                      {/* ************INSIGHTS**************** */}

                      <button className="my-1 btn btn-primary" onClick={showModal}>
                        {" "}
                        View Insights
                      </button>

                      {/* {address && (
                        <>
                          {TokenID ? (
                            <button className="my-1 btn btn-primary mx-2" onClick={updateMetadatafx}>
                              {" "}
                              Update Meta Data
                            </button>
                          ) : (
                            <>
                              <button className="my-1 btn btn-primary mx-4" onClick={(e) => handleMint(e)}>
                                {" "}
                                Mint token
                              </button>
                            </>
                          )}
                        </>
                      )} */}

                      {/* {address && (
                        <>
                          {TokenID && (
                            <button className="my-1 btn btn-primary mx-2" onClick={updateMetadatafx}>
                              {" "}
                              Update Meta Data
                            </button>
                          )}
                        </>
                      )} */}

                      {/* {TokenID && ( */}
                      <button className="my-1 btn btn-primary mx-5 " onClick={HandleTransaction} style={{ color: "white" }}>
                        Transaction History
                      </button>

                      {/* <button onClick={handleAddd}>
                        addpost
                      </button>
                      <button onClick={handleAdddMint}>
                        addmint
                      </button> */}
                      {/* )} */}

                      {/* <button className="my-1 btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" disabled={isInsiteDataLoading}>
                        {" "}
                        View Insights
                      </button> */}
                      {/* <div className="d-flex justify-content-start rounded-3 p-2 mb-2 bg-body-tertiary">


                        <div className="wid-100">
                          <p className="small text-muted mb-1">Articles</p>
                          <p className="mb-0">41</p>
                        </div>
                        <div className="px-3 wid-100">
                          <p className="small text-muted mb-1">Account Type</p>
                          <p className="mb-0">{InstagramUserDetails?.account_type ? InstagramUserDetails?.account_type : "Null"}</p>
                        </div>
                        <div className="wid-100">
                          <p className="small text-muted mb-1">Media Count</p>
                          <p className="mb-0">{InstagramUserDetails?.media_count ? InstagramUserDetails?.media_count : "Null"}</p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  {/* Commnent/? */}
                  <div className="my-3">
                    Enter the Comment Count to store Posts to the blockchain
                    <input
                      type="number"
                      className="form-control"
                      onChange={(e) => localStorage.setItem("CommentCount", e.target.value)}
                      defaultValue={localStorage.getItem("CommentCount")}
                      placeholder="Enter your comment count"
                      min={0}
                    />
                  </div>

                  {/* Multiple? */}

                  <div className="my-3">
                    Enter the Count to store Multiple Posts to the blockchain
                    <input
                      type="number"
                      className="form-control"
                      onChange={(e) => localStorage.setItem("MultipleCount", e.target.value)}
                      defaultValue={localStorage.getItem("MultipleCount")}
                      placeholder="Enter your multiple post count"
                      min={0}
                    />
                  </div>

                  {/* <button onClick={Handlemulti}>Addmultiple</button> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card cus-m" style={{ borderRadius: "15px" }}>
                <div className="card-body p-4 ">
                  <div className="container123">
                    <div className="form-container">
                      <h2 className="form-header">Instagram Post Form</h2>
                      <form>
                        <div className="mb-3">
                          <label htmlFor="formFile" className="form-label">
                            Upload Image
                          </label>
                          <input
                            style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px", background: "#f5f7ff " }}
                            className="form-control"
                            ref={imageInputRef}
                            accept=".jpg, .jpeg"
                            type="file"
                            id="image"
                            onChange={(e) => HandleFileChange(e)}
                          />

                          <div className="my-3">
                            {S3BucketImage?.length > 0 && (
                              <>
                                <a href={S3BucketImage} target="_blank">
                                  {S3BucketImage}
                                </a>
                              </>
                            )}
                          </div>
                          <p style={{ color: "red" }}>{error?.image}</p>
                          {error?.imgerr && <p style={{ color: "red" }}>{error?.imgerr}</p>}
                          {error?.imgsize && <p style={{ color: "red" }}>{error?.imgsize}</p>}
                        </div>

                        <div className="mb-3">
                          <label htmlFor="caption" className="form-label">
                            Caption
                          </label>
                          <textarea
                            style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px", background: "#f5f7ff" }}
                            className="form-control"
                            id="caption"
                            value={instaonchangedata?.caption}
                            onChange={(e) => HandleChange(e)}
                            rows="3"
                            placeholder="Enter your caption"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                HandleSubmit();
                              }
                            }}
                          ></textarea>
                          <p style={{ color: "red" }}>{error?.caption}</p>
                        </div>
                        {InstagramProfileData ? (
                          <div>
                            <button type="submit" className="btn btn-primary w-100" onClick={(e) => HandleSubmit(e)}>
                              Post Image
                            </button>
                          </div>
                        ) : (
                          <div>
                            <button type="submit" className="btn btn-primary w-100" onClick={(e) => HandleLoginInsta(e)}>
                              Login to Instagram
                            </button>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4"></div>
      </div>

      <Modal title={InstagramProfileData ? "Insight details" : "Instagram Access"} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
        {InstagramProfileData ? (
          <InsightModal InstagramProfileData={InstagramProfileData} />
        ) : (
          <div className="card">
            <div className="p-3 my-2">
              <div className="mb-3">
                <label htmlFor="username" className="form-label">
                  Username
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={instaonchangedataModal?.username}
                  onChange={(e) => HandleChangeForModal(e)}
                  id="username"
                  placeholder="Enter your instagram username"
                  autoComplete="new-username"
                />
                <p style={{ color: "red" }}>{errorForModal?.username}</p>
              </div>
              <div className="mb-3 position-relative">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <input
                  type={togglePassword ? "text" : "password"}
                  className="form-control"
                  value={instaonchangedataModal?.password}
                  onChange={(e) => HandleChangeForModal(e)}
                  id="password"
                  autoComplete="new-password"
                  placeholder="Enter your instagram password"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      HandleViewInsite();
                    }
                  }}
                />
                <p style={{ color: "red" }}>{errorForModal?.password}</p>
                <div className="pass-toggle1">
                  <FontAwesomeIcon icon={togglePassword ? faEyeSlash : faEye} className="password-toggle-icon" onClick={TogglePasswordVisibility} />
                </div>
              </div>

              <div className="mb-3">
                <input type="checkbox" onClick={(e) => HandleChecked(e)} checked={rememberMe} />{" "}
                <span className="mx-1" style={{ position: "relative", top: "-15px" }}>
                  Remember me
                </span>
              </div>

              <button className="btn btn-primary w-100" onKeyDown={(e) => {}} onClick={(e) => HandleViewInsite(e)}>
                Submit
              </button>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default Instagram;
